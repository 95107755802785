import { defineStore } from "pinia";

export const lotesStore = defineStore('sessionLote', {
    state: () => ({
        historicoFiltrosLotes: {},
        historicoResultadosLotes: [],
    }),
    actions: {
        
    }
})
