import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";
import { consultasService } from "@/services";
import jsonProdutos from "@/helpers/produtos.json";
import jsonSuperBox from "@/helpers/produtos_superbox.json";
import listaProdutos from "@/helpers/lista_produtos.json";

export const produtosStore = defineStore("produtos", {
  state: () => ({
    produtos: [],
    modelos: [],
    produtosLotes: [],
    produtosSuperBox: [],
    produtosSuperBoxCust: [],
    codigo_agrupador_box: {},
    codigo_agrupador: "",
  }),
  actions: {
    resetStore() {
      this.produtos = [];
      this.modelos = [];
    },

    async getProdutos() {
      const rotas = await consultasService.recuperaPermissoes();
      const v3 = await rotas.filter(
        (rota) => rota.CodigoRota === "v3/consulta"
      );
      if (v3.length > 0) {
        //produtos liberados na API
        var produtosPermitidos =
          v3[0].PermissoesParametro[0].DominioValores.map((item) => item.Valor);
        this.produtosLotes = produtosPermitidos;

        //filtro dos produtos de prateleira em relação aos com permissão na API
        var objetoProdutos = jsonProdutos.produtos;
        var produtos = objetoProdutos.filter((produto) =>
          produtosPermitidos.includes(produto.Box)
        );
        this.produtos = produtos;

        //força entrada do bloco 9 a lista de produtos
        var existeBox001 = produtos.some(
          (produto) => produto.Box === "BOXSUPPF001"
        );
        if (existeBox001) {
          var produtoAlertaSuperbox = objetoProdutos.filter(
            (produto) => produto.Box === "BOXSUPPF009"
          );
          this.produtos.push(...produtoAlertaSuperbox);
        }
        //produtos que são utilizados na super box
        this.produtosSuperBox = jsonSuperBox.produtosSuperBox;
        this.produtosSuperBoxCust = produtos.filter(
          (item) => item.Box.startsWith("BOXSUPPF") && item.Box.includes("_")
        );
      }

      const b3 = await rotas.filter((rota) => rota.CodigoRota === "v3/box");
      if (b3.length > 0) {
        var produtosPermitidos =
          b3[0].PermissoesParametro[0].DominioValores.map((item) => item.Valor);
        this.produtosLotes = produtosPermitidos;

        var objetoProdutos = jsonProdutos.produtos;
        var produtos = objetoProdutos.filter((produto) =>
          produtosPermitidos.includes(produto.Box)
        );

        var produtosFiltrados = produtos.filter((novoProduto) => {
          return !this.produtos.some(
            (produtoExistente) => produtoExistente.Box === novoProduto.Box
          );
        });

        this.produtos = [...this.produtos, ...produtosFiltrados];
      }

      // const v3box = await rotas.filter((rota) => rota.CodigoRota === 'v3/box')
      // if (v3box.length > 0) {
      //     // var boxPermitidas = v3box[0].PermissoesParametro[0].DominioValores.map((item) => item.Valor)
      //     var boxPermitidas = v3box[0].PermissoesParametro[0].DominioValores

      //     this.produtosSuperBoxCust = boxPermitidas.filter((item) => item.Valor.startsWith('BOXSUPPF'))
      // }
    },

    async getModelos() {
      const rotas = await consultasService.recuperaPermissoes();
      const v3 = await rotas.filter((rota) => rota.CodigoRota === "v3/modelo");
      var produtosPermitidos = []
      if (v3.length > 0) {
        produtosPermitidos = v3[0].PermissoesParametro[0].DominioValores;
      }

      this.modelos = produtosPermitidos;
    },

    produtosAusentes(produtosConsultados, tipoConsulta) {
      const produtosDoMesmoTipo = Object.keys(
        listaProdutos[tipoConsulta]
      ).filter(
        (product) => !Object.keys(produtosConsultados).includes(product)
      );
      const produtosPermitidos = this.produtos.map((produto) => produto.Box);
      const produtosNaoConsultados = produtosDoMesmoTipo.filter((produto) =>
        produtosPermitidos.includes(produto)
      );
      return produtosNaoConsultados;
    },

    async criaCodigoAgrupador() {
      this.codigo_agrupador = await this.geraCodigoAgrupador();
    },

    async geraCodigoAgrupadorMetadados(valor) {
      var objetoConsulta = {};
      objetoConsulta["metadados"] = valor

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(objetoConsulta),
      };

      let url =
        apiService.enderecoApi() + "/v3/cod_agrupador";
      return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          this.codigo_agrupador_box = resultado
          this.codigo_agrupador = resultado.cod_agrupador
          return resultado;
        });
    },

    async geraCodigoAgrupador() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let url = apiService.enderecoApi() + "/v3/cod_agrupador";

      const cod_agrupador = await fetch(url, requestOptions).then((cod) => {
        return apiService.handleResponse(cod);
      });
      return cod_agrupador["cod_agrupador"];
    },

    resetaCodigoAgrupador() {
      this.codigo_agrupador = "";
    },
  },
});
