import { type } from 'jquery';
import { defineStore } from 'pinia';

export const useSkynetMemoryStore = defineStore('skynetMemory', {
  state: () => ({
    identificadorMemory: "",
    projectName: "",
    varRespMemory: "",
    respMau: "Valor_1",
    varAmostraMemory: {},
    varAmostraTreinoMemory: {},
    varReservadasMemory: [],
    covarSelecionadasMemory: [],
    linguagemMemory: "",
    abordagemMemory: {},
    resultadoIntegracaoMemory: null,
    msgErroIntegracaoMemory: "",
    counterMemory: 0,
    rotaConsulta: "",
    abordagemConsulta: ""
  }),

  getters: {
    count: state => state.todos.length
  },

  actions: {
    setProjectName(name, id) {
      this.projectName = name
      this.identificadorMemory = id
    },

    setTipoConsulta(prod, codRef) {
      this.rotaConsulta = prod
      this.abordagemConsulta = codRef
    },

    setVarResposta(varResp) {
      this.varRespMemory = varResp
    },
    setMaus(maus) {
      this.respMau = maus
    },
    setVarAmostra(varAmostra, amostraIndex) {
      this.varAmostraMemory[amostraIndex] = varAmostra
    },
    setVarAmostraTreino(amostraTreino, amostraTreinoIndex) {
      this.varAmostraTreinoMemory[amostraTreinoIndex] = amostraTreino
    },
    deleteMemoryAmostra(amostraIndex) {
      delete this.varAmostraMemory[amostraIndex]
    },
    setVarReservadas(varReserv) {
      this.varReservadasMemory = varReserv
    },
    setCovarSelecionadas(covarSelecionadas) {
      this.covarSelecionadasMemory = covarSelecionadas
    },
    setLiguagem(linguagem) {
      this.linguagemMemory = linguagem
    },
    setAbordagem(abordagem) {
      this.abordagemMemory = abordagem
    },
    setResultadoIntegracao(resInteg, msgErro, counter) {
      this.resultadoIntegracaoMemory = resInteg
      this.msgErroIntegracaoMemory = msgErro
      this.counterMemory = counter
    },
    
    resetParametros() {
      this.varRespMemory = "",
      this.respMau = "Valor_1",
      this.varAmostraMemory = {},
      this.varAmostraTreinoMemory = {},
      this.varReservadasMemory = [],
      this.covarSelecionadasMemory = [],
      this.linguagemMemory = "",
      this.abordagemMemory = {}
    }
  }
});