import { type } from 'jquery';
import { defineStore } from 'pinia';

export const useSkynetStore = defineStore('skynet', {
  state: () => ({
    availableCols: [],
    selectedColsSkynet: [],
    varReservadas: [],
    reservadasAtualizadasMemory: [],
    covarReservadas: [],
    selectCovar: [],
    cabecalhoSaidaSelect: [],
    preSelecionadas: [],
    formatData: "",
    docColumn: "",
    dataRefColumn: "",
    varAmostraQtd: 0,
    tipoPessoaMemory: "",

  }),

  actions: {
    //recupera coluna não mapeada da API - passo0
    setCols(colNotMapped) {
      this.availableCols = colNotMapped.sort()
    },

    //retorna valor para colunar disponiveis
    addCol(val) {
      this.availableCols.push(val)
      this.selectedColsSkynet.forEach((col, i) => {
        if (val === col) {
          this.selectedColsSkynet.splice(i, 1)
        }
      })

    },

    //retira valor das colunas disponiveis e adicionar nas colunas selecionadas
    updateCol(col, bef) {
      if (bef != "") this.addCol(bef)

      this.availableCols.forEach((val, i) => {
        if (val == col) {
          this.selectedColsSkynet.push(val) //colunas ja selecionadas
          this.availableCols.splice(i, 1); // colunas disponiveis
          return
        }
      })
      this.availableCols.sort()
    },

    //sem before, retira valor das colunas disponiveis
    updateColUnit(col) {
      this.availableCols.forEach((val, i) => {
        if (val == col) {
          this.selectedColsSkynet.push(val) //colunas ja selecionadas
          this.availableCols.splice(i, 1); // colunas disponiveis
          return
        }
      })
      this.availableCols.sort()
    },

    //atualiza as variaveis reservadas que foram selecionadas
    setPreSelecionadas(selecionadas) {
      this.varReservadas = selecionadas
      this.reservadasAtualizadasMemory =  [];
      
      this.reservadasAtualizadasMemory = 
      this.reservadasAtualizadasMemory.concat(this.varReservadas)
      .concat(this.preSelecionadas);

      this.availableCols = this.availableCols.filter(x => 
        !this.varReservadas.includes(x))
    },

    //atributos da Saida que irão para o select da reservadas (sem as colunas)
    setcabecalhoSaidaSelect(cabecalho) {
      this.cabecalhoSaidaSelect = cabecalho
    },

    //atualiza valores do select das covariaveis, filtrando reservadas selecionadas
    setCovarSelect(reservadasAtualizadas) {
      this.selectCovar = []
      this.selectCovar = Object.values(this.cabecalhoSaidaSelect).filter(x => 
        !Object.values(reservadasAtualizadas).includes(x))
    },

    //atualiza covariaveis selecionadas - vao para o POST
    setCovarReservadas(covariaveisSelecionadas) {
      this.covarReservadas = []
      this.covarReservadas = covariaveisSelecionadas
      this.availableCols = this.availableCols.filter(x => 
        !this.covarReservadas.includes(x))
    },

    //iniciliza as preselecionadas no passo da variavel chave
    initPreSelecionadas(preSelect) {
      this.preSelecionadas = preSelect
      this.reservadasAtualizadasMemory = this.preSelecionadas
    },

    setFormatData(format, tipoPessoa) {
      this.formatData = format.split("=")[1]
      this.tipoPessoaMemory = tipoPessoa
    },

    setDataRefColumn(column) {
      this.dataRefColumn = column
    },
    setDocColumn(column) {
      this.docColumn = column
    },

    setVarAmostraQtd(qtdAmostras) {
      this.varAmostraQtd = Object.keys(qtdAmostras).length
    }

  }
});